export const KEY = (() => {
    return {
        A: 'a',
        B: 'b',
        X: 'x',
        Y: 'y',
        Z: 'z',
        L: 'l',
        R: 'r',
        START: 'start',
        SELECT: 'select',
        LOAD: 'load',
        SAVE: 'save',
        HELP: 'help',
        JOIN: 'join',
        FULL: 'full',
        QUIT: 'quit',
        UP: 'up',
        DOWN: 'down',
        LEFT: 'left',
        RIGHT: 'right',
        PAD1: 'pad1',
        PAD2: 'pad2',
        PAD3: 'pad3',
        PAD4: 'pad4',
        STATS: 'stats',
        SETTINGS: 'settings',
        DTOGGLE: 'dtoggle',
        L2: 'l2',
        R2: 'r2',
        L3: 'l3',
        R3: 'r3',
        MULTITAP: 'multitap',
        REC: 'rec',
    }
})();
