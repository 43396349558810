export const colors = {
    black: '#000',
    charcoal: '#212121',
    white: '#fff',
    blue: '#013094',
    darkBlue: '#012572',
    yellow: '#FDAC00',
    orange: '#FC8831',
    gray: '#8E8E93',
    gray2: '#373737',
    grayLight: '#C7C7CC',
    cream: '#F3EBDD',
    beige: '#E5D8BF',
}